import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider, useThemeContext } from './contexts/ThemeContext';
import { LanguageProvider } from './contexts/LanguageContext';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute/index';
import reportWebVitals from './reportWebVitals';
import Home from './pages/Home';
import Browse from './pages/Browse';
import MovieDetail from './pages/MovieDetail';
import Search from './pages/Search';
import Watch from './pages/Watch';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Guestbook from './components/Guestbook/Guestbook';
import Login from './pages/Login';
import Register from './pages/Register';
import Member from './pages/Member';
import AppDownload from './pages/AppDownload';

function App() {
  useEffect(() => {
    // Initialize web vitals tracking
    reportWebVitals();
  }, []);

  return (
    <HelmetProvider>
      <ThemeProvider>
        <LanguageProvider>
          <AuthProvider>
            <AppContent />
          </AuthProvider>
        </LanguageProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

function AppContent() {
  const { theme } = useThemeContext();

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/browse" element={<Browse />} />
          <Route path="/detail/:id" element={<MovieDetail />} />
          <Route path="/search" element={<Search />} />
          <Route path="/watch/:id" element={<Watch />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/guestbook" element={<Guestbook />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/member"
            element={
              <PrivateRoute>
                <Member />
              </PrivateRoute>
            }
          />
          <Route path="/app" element={<AppDownload />} />
        </Routes>
      </Router>
    </MuiThemeProvider>
  );
}

export default App; 