import React from 'react';
import { Box, Container, Typography, Button, Paper, Stack, useTheme, alpha } from '@mui/material';
import { Link } from 'react-router-dom';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import AndroidIcon from '@mui/icons-material/Android';
import ChatIcon from '@mui/icons-material/Chat';
import Layout from '../components/Layout';
import { useLanguage } from '../contexts/LanguageContext';

const AppDownload = () => {
  const theme = useTheme();
  const { t } = useLanguage();

  const handleImageClick = (imageUrl) => {
    window.open(imageUrl, '_blank');
  };

  return (
    <Layout>
      <Container 
        maxWidth="lg" 
        sx={{ 
          pt: { xs: 8, sm: 9, md: 10 },
          pb: { xs: 4, sm: 6, md: 8 },
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: 4, sm: 6 }
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            variant="h3"
            component="h1"
            sx={{
              fontWeight: 700,
              mb: 2,
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }
            }}
          >
            {t('app', 'title')}
          </Typography>
          <Typography
            variant="h6"
            color="text.secondary"
            sx={{
              maxWidth: '800px',
              mx: 'auto',
              mb: 4,
              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' }
            }}
          >
            {t('app', 'subtitle')}
          </Typography>
        </Box>

        <Stack spacing={4} alignItems="center">
          {/* Preview Images */}
          <Box sx={{ 
            width: '100%',
            maxWidth: '1200px',
            mx: 'auto',
            px: { xs: 2, sm: 4 }
          }}>
            <Stack spacing={3}>
              {[1, 2, 3].map((num) => (
                <Paper
                  key={num}
                  elevation={4}
                  sx={{
                    width: '100%',
                    borderRadius: 2,
                    overflow: 'hidden',
                    transition: 'transform 0.3s ease-in-out',
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                    }
                  }}
                  onClick={() => handleImageClick(`/images/app-preview-${num}.png`)}
                >
                  <Box
                    component="img"
                    src={`/images/app-preview-${num}.png`}
                    alt={`247看 App Preview ${num}`}
                    sx={{
                      width: '100%',
                      height: 'auto',
                      display: 'block',
                      aspectRatio: '3/2',
                      objectFit: 'cover',
                    }}
                  />
                </Paper>
              ))}
            </Stack>
          </Box>

          {/* Features and Download Section */}
          <Paper
            elevation={0}
            sx={{
              p: { xs: 3, sm: 4, md: 5 },
              borderRadius: 4,
              bgcolor: alpha(theme.palette.primary.main, 0.04),
              border: '1px solid',
              borderColor: 'divider',
              maxWidth: '800px',
              mx: 'auto',
              width: '100%',
              textAlign: 'center'
            }}
          >
            <Stack spacing={4} alignItems="center">
              <Box sx={{ textAlign: 'center', maxWidth: '600px', mx: 'auto' }}>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 600,
                    mb: 2,
                    fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' }
                  }}
                >
                  {t('app', 'comingSoon')}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                >
                  {t('app', 'description')}
                </Typography>
              </Box>

              {/* Feature List */}
              <Box sx={{ 
                width: '100%',
                maxWidth: '500px',
                mx: 'auto'
              }}>
                <Stack 
                  spacing={2} 
                  sx={{ 
                    mb: 4,
                    px: { xs: 2, sm: 4 }
                  }}
                >
                  {t('app', 'features').split('\n').map((feature, index) => (
                    <Box key={index} sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 2,
                      justifyContent: 'flex-start',
                      width: '100%'
                    }}>
                      <Box
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          bgcolor: 'primary.main',
                          flexShrink: 0,
                        }}
                      />
                      <Typography sx={{ textAlign: 'left' }}>{feature}</Typography>
                    </Box>
                  ))}
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                  <Button
                    variant="contained"
                    size="large"
                    disabled
                    startIcon={<AndroidIcon />}
                    fullWidth
                    sx={{ 
                      py: 1.5,
                      borderRadius: 2,
                      fontSize: '1.1rem'
                    }}
                  >
                    {t('app', 'androidApp')}
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    disabled
                    startIcon={<PhoneIphoneIcon />}
                    fullWidth
                    sx={{ 
                      py: 1.5,
                      borderRadius: 2,
                      fontSize: '1.1rem'
                    }}
                  >
                    {t('app', 'iosApp')}
                  </Button>
                </Stack>
              </Box>

              <Box sx={{ textAlign: 'center', width: '100%' }}>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  {t('app', 'feedbackPrompt')}
                </Typography>
                <Button
                  component={Link}
                  to="/guestbook"
                  variant="outlined"
                  startIcon={<ChatIcon />}
                  sx={{ 
                    borderRadius: 2,
                    px: 3
                  }}
                >
                  {t('app', 'gotoGuestbook')}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Stack>
      </Container>
    </Layout>
  );
};

export default AppDownload; 